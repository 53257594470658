import "./redblack-card.style.scss";
import { ReactComponent as ClubsCenter } from "./assets/clubs-center.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ReactComponent as DiamondsCenter } from "./assets/diamonds-center.svg";
import { ReactComponent as HeartsCenter } from "./assets/hearts-center.svg";
import { RedBlackCardSymbol } from "../redblack-card-symbol/redblack-card-symbol.component";
import { RedBlackContext, RedBlackContextValue } from "../../contexts/redblack.context";
import { RedBlackHelper } from "../../common/helpers/redblack.helper";
import { RedBlackResultInfo } from "../../common/interfaces/redblack-result-info.interface";
import { ReactComponent as SpadesCenter } from "./assets/spades-center.svg";
import classNames from "classnames";
import React, { ReactNode } from "react";

export interface RedBlackCardProperties {
    result?: number;
    theme?: RedBlackContextValue["theme"];
    className?: string;
    onClick?: () => void;
}

export class RedBlackCard extends React.PureComponent<RedBlackCardProperties, {
    result?: RedBlackResultInfo;
}> {
    public constructor(props: RedBlackCardProperties) {
        super(props);
        this.state = {
            result: props.result != null ? RedBlackHelper.getResultInfo(props.result) : undefined,
        };
    }

    public componentDidUpdate(oldProps: RedBlackCardProperties): void {
        if (oldProps.result !== this.props.result && this.props.result != null) {
            this.setState(
                {
                    result: RedBlackHelper.getResultInfo(this.props.result),
                }
            );
        }
    }

    public render(): ReactNode {
        const { theme: forcedTheme, result: actualResult, className, onClick } = this.props;
        const { result: visualResult } = this.state;

        const content = <RedBlackContext.Consumer>
            {
                (contextValue) => {
                    const theme = forcedTheme ?? contextValue.theme ?? "light";

                    return <SwitchTransition
                        mode="in-out"
                    >
                        <CSSTransition
                            key={theme}
                            classNames="fade"
                            timeout={300}
                        >
                            <div
                                className={
                                    classNames(
                                        "redblack-card-inner",
                                        {
                                            ["redblack-card-inner-light"]: theme === "light",
                                            ["redblack-card-inner-dark"]: theme === "dark",
                                            ["redblack-card-inner-revealed"]: actualResult != null,
                                        }
                                    )
                                }
                            >
                                {this.renderCardBack()}
                                {this.renderCardFront(visualResult)}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>;
                }
            }
        </RedBlackContext.Consumer>;

        if (onClick) {
            return <button className={classNames("redblack-card", className)} onClick={onClick}>{content}</button>;
        }
        return <div className={classNames("redblack-card", className)}>{content}</div>;
    }

    private renderCardBack(): ReactNode {
        return <article className="redblack-card-back" />;
    }

    private renderCardFront(result?: RedBlackResultInfo): ReactNode {
        let SymbolCenter: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined;
        switch (result?.symbol) {
            case "clubs":
                SymbolCenter = ClubsCenter;
                break;
            case "diamonds":
                SymbolCenter = DiamondsCenter;
                break;
            case "hearts":
                SymbolCenter = HeartsCenter;
                break;
            case "spades":
                SymbolCenter = SpadesCenter;
                break;
        }

        return <article
            className={
                classNames(
                    "redblack-card-front",
                    {
                        ["redblack-card-front-red"]: result?.color === "red",
                        ["redblack-card-front-black"]: result?.color === "black",
                        ["redblack-card-front-joker"]: result?.color === "joker",
                    }
                )
            }
        >
            {
                result && !!Symbol && <>
                    <header>
                        <h3>{result.character}</h3>
                        <RedBlackCardSymbol symbol={result.symbol} />
                    </header>
                    <main>
                        {/* <h2>{result.character}</h2> */}
                        {SymbolCenter && <SymbolCenter />}
                    </main>
                    <footer>
                        <h3>{result.character}</h3>
                        <RedBlackCardSymbol symbol={result.symbol} />
                    </footer>
                </>
            }
        </article>;
    }
}
