import { BaseService , ResultSet, ServiceException } from "@tgg/shared";
import { RedBlackEndMatchRequestDto } from "./dtos/redblack-end-match-request.dto";
import { RedBlackMatchDto } from "./dtos/redblack-match.dto";
import { RedBlackMatchParametersDto } from "./dtos/redblack-match-parameters.dto";
import { RedBlackPredictHandRequestDto } from "./dtos/redblack-predict-hand-request.dto";

export class RedBlackService extends BaseService {
    private token?: string;

    public constructor(baseUrl?: string) {
        super(baseUrl);
    }

    public async predictMatch(prediction: RedBlackPredictHandRequestDto): Promise<RedBlackMatchDto> {
        return await this.requestRest<RedBlackMatchDto, RedBlackPredictHandRequestDto>(
            "/my/predict",
            {},
            {},
            "POST",
            prediction
        );
    }

    public async endMatch(matchId: number): Promise<RedBlackMatchDto> {
        return await this.requestRest<RedBlackMatchDto, RedBlackEndMatchRequestDto>(
            "/my/end",
            {},
            {},
            "POST",
            {
                matchId,
            }
        );
    }

    public async getMyMatches(query?: RedBlackMatchParametersDto): Promise<ResultSet<RedBlackMatchDto>> {
        return await this.requestRest<ResultSet<RedBlackMatchDto>>(
            "/my/matches",
            {},
            query ?? {},
            "GET"
        );
    }

    public async getMyMatch(): Promise<RedBlackMatchDto | null> {
        try {
            return await this.requestRest<RedBlackMatchDto>(
                "/my",
                {},
                {},
                "GET"
            );
        } catch (error) {
            if (error instanceof ServiceException && error.status === 404) {
                return null;
            }

            throw error;
        }
    }

    public getToken(): string | undefined {
        return this.token;
    }

    public setToken(value: string): void {
        this.token = value;
    }
}
