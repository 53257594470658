import "./redblack-menu.style.scss";
import { AccountingHelper, AudioHelper } from "@tgg/shared";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { RedBlackContext } from "../../contexts/redblack.context";
import { RedBlackHistory } from "../redblack-history/redblack-history.component";
import { RedBlackInstructions } from "../redblack-instructions/redblack-instructions.component";
import { RedBlackSummary } from "../redblack-summary/redblack-summary.component";
import classNames from "classnames";
import React, { ReactNode } from "react";

export class RedBlackMenu extends React.PureComponent<unknown, {
    openModal?: "instructions" | "history" | "summary";
}> {
    private slideAudio: HTMLAudioElement | undefined;

    public constructor(props: unknown) {
        super(props);
        this.state = {};
    }

    public componentDidMount(): void {
        if (!this.slideAudio) {
            void AudioHelper.loadAudio("/redblack-card-slide.mp3").then(
                (a) => this.slideAudio = a
            );
        }
    }

    public componentDidUpdate(_oldProps: unknown, oldState: this["state"]): void {
        if (oldState.openModal !== this.state.openModal) {
            AudioHelper.playAudio(this.slideAudio);
        }
    }

    public render(): ReactNode {
        const isModalOpen = this.state.openModal != null;
        return <RedBlackContext.Consumer>
            {
                (context) =>
                    <section
                        className={
                            classNames(
                                "redblack-menu",
                                {
                                    ["redblack-menu-active"]: isModalOpen,
                                }
                            )
                        }>
                        <header>
                            <Logo />
                        </header>
                        <main>
                            {
                                this.renderModal(
                                    <RedBlackInstructions />,
                                    "How To Play",
                                    "instructions"
                                )
                            }
                            {
                                this.renderModal(
                                    <RedBlackHistory getMatches={context.getMatched} />,
                                    "Bet History",
                                    "history"
                                )
                            }
                            {
                                this.renderModal(
                                    <RedBlackSummary onEndMatch={() => this.setState({ openModal: undefined })} />,
                                    "Start New Round",
                                    "summary",
                                    context.balance == null || context.match == null
                                )
                            }
                        </main>
                        <footer>
                            <div>
                                <span>
                                    {
                                        context.balance != null ?
                                            `Wallet ${context.currencySign ?? "$"}${AccountingHelper.formatBalance(context.balance, context.decimals)}` :
                                            "Login"
                                    }
                                </span>
                            </div>
                        </footer>
                        <CSSTransition
                            in={isModalOpen}
                            classNames="fade"
                            timeout={300}
                            unmountOnExit={true}
                        >
                            <span className="redblack-modal-overlay" onClick={() => this.setState({ openModal: undefined })} />
                        </CSSTransition>
                    </section>
            }
        </RedBlackContext.Consumer>;
    }

    private renderModal(content: React.ReactNode, title: string, key: this["state"]["openModal"], isDisable?: boolean) {
        const isOpen = this.state.openModal === key;
        return <CSSTransition
            key={key}
            in={isOpen}
            classNames="modal-reveal"
            timeout={300}
        >
            <div
                className={
                    classNames(
                        "redblack-menu-modal",
                        {
                            ["redblack-menu-modal-active"]: isOpen,
                            ["redblack-menu-modal-disabled"]: isDisable,
                        }
                    )
                }
                onClick={(!isDisable && !isOpen) ? (() => this.setState({ openModal: key })) : undefined}
            >
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit={true}
                >
                    <article>{content}</article>
                </CSSTransition>
                <h2>{title}</h2>
            </div>
        </CSSTransition>;
    }
}
