import { ReactComponent as Clubs } from "./assets/clubs.svg";
import { ReactComponent as Diamonds } from "./assets/diamonds.svg";
import { ReactComponent as Hearts } from "./assets/hearts.svg";
import { RedBlackResultInfo } from "../../common/interfaces/redblack-result-info.interface";
import { ReactComponent as Spades } from "./assets/spades.svg";
import React, { ReactNode } from "react";

export interface RedBlackCardSymbolProperties extends React.SVGProps<SVGSVGElement> {
    symbol: RedBlackResultInfo["symbol"];
}

export class RedBlackCardSymbol extends React.PureComponent<RedBlackCardSymbolProperties> {
    public render(): ReactNode {
        const { symbol, ...svgProperties } = this.props;

        let Symbol: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined;
        switch (symbol) {
            case "clubs":
                Symbol = Clubs;
                break;
            case "diamonds":
                Symbol = Diamonds;
                break;
            case "hearts":
                Symbol = Hearts;
                break;
            case "spades":
                Symbol = Spades;
                break;
        }

        if (!Symbol) {
            return <></>;
        }

        return <Symbol {...svgProperties} />;
    }
}
