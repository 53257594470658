import "./redblack-history.style.scss";
import { AccountingHelper, DateHelper, ResultSet } from "@tgg/shared";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { RedBlackCardSymbol } from "../redblack-card-symbol/redblack-card-symbol.component";
import { RedBlackContext } from "../../contexts/redblack.context";
import { RedBlackHandDto } from "../../services/redblack/dtos/redblack-hand.dto";
import { RedBlackHandPrediction } from "../../services/redblack/enums/redblack-hand-prediction.enum";
import { RedBlackHelper } from "../../common/helpers/redblack.helper";
import { RedBlackMatchDto } from "../../services/redblack/dtos/redblack-match.dto";
import classNames from "classnames";
import React, { ReactNode } from "react";

export interface RedBlackHistoryProperties {
    getMatches?: () => Promise<ResultSet<RedBlackMatchDto>>;
}

export class RedBlackHistory extends React.PureComponent<RedBlackHistoryProperties, {
    matches?: RedBlackMatchDto[];
    isLoading: boolean;
}> {
    public constructor(props: RedBlackHistoryProperties) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        setTimeout(
            () => {
                if (!this.props.getMatches) {
                    return;
                }

                void this.props.getMatches()
                    .catch(() => undefined)
                    .then(
                        (matches) => {
                            this.setState(
                                {
                                    matches: matches?.result,
                                    isLoading: false,
                                }
                            );
                        }
                    );
            },
            1000
        );
    }

    public render(): ReactNode {
        return <div className="redblack-history">
            <SwitchTransition
                mode="out-in"
            >
                <CSSTransition
                    key={this.state.isLoading ? "loading" : "date"}
                    classNames="fade"
                    timeout={300}
                    unmountOnExit={true}
                >
                    <>
                        {this.state.isLoading && <span>LOADING...</span>}
                        {
                            !this.state.isLoading &&
                                <table>
                                    <thead>
                                        <th>ID</th>
                                        <th colSpan={2}>Pick</th>
                                        <th colSpan={2}>Result</th>
                                        <th>Date</th>
                                    </thead>
                                    <tbody>
                                        {this.state.matches?.map((m) => this.renderMatch(m))}
                                    </tbody>
                                </table>
                        }
                    </>
                </CSSTransition>
            </SwitchTransition>
        </div>;
    }

    private renderMatch(match: RedBlackMatchDto) {
        return [ ...match.hands ].reverse().map((h) => this.renderHand(h));
    }

    private renderHand(hand: RedBlackHandDto): ReactNode {
        const result = RedBlackHelper.getResultInfo(hand.result);
        const symbol = result?.symbol ?? "joker";

        return <RedBlackContext.Consumer>
            {

                (context) => <tr key={`${hand.id}`}>
                    <td>
                        {`${hand.id}`}
                    </td>
                    <td>
                        {AccountingHelper.formatMoney(hand.amount, context.decimals)}
                    </td>
                    <td
                        className={
                            classNames(
                                {
                                    ["black"]: hand.prediction === RedBlackHandPrediction.Black,
                                    ["red"]: hand.prediction === RedBlackHandPrediction.Red,
                                }
                            )
                        }
                    >
                        <i />
                    </td>
                    <td>
                        +{AccountingHelper.formatMoney(hand.won ? (hand.amount ? (hand.amount * 2) : hand.amount) : 0, context.decimals)}
                    </td>
                    <td>
                        <span
                            className={
                                classNames(
                                    {
                                        ["black"]: result?.color === "black",
                                        ["red"]: result?.color === "red",
                                        ["joker"]: result?.color === "joker",
                                    }
                                )
                            }
                        >
                            <span>{result?.character ?? "JK"}</span>
                            {symbol === "joker" && <i />}
                            {symbol !== "joker" && <RedBlackCardSymbol symbol={symbol} />}
                        </span>
                    </td>
                    <td>
                        {DateHelper.formatDateShort(new Date(hand.settled ?? hand.created))}
                    </td>
                </tr>
            }
        </RedBlackContext.Consumer>;
    }
}
