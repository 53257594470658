import "./redblack-instructions.style.scss";
import React, { ReactNode } from "react";

export class RedBlackInstructions extends React.PureComponent {
    public render(): ReactNode {
        return <div className="redblack-instructions">
            <h4>About Red/Black</h4>
            <ol>
                <li>
                    The objective of this game is to predict the color of the drawn card, which can be Red, Black, or Purple.
                </li>
                <li>
                    Each round of this game consists of up to a maximum of 5 playable turns (hands).
                    In other words, the player can bet on the color of the next face-down card drawn from the deck for a maximum of 5 times in a particular round.
                </li>
                <li>
                    Please note that each round is played using a single, well-shuffled deck of 52 cards plus jokers.
                    The player has the advantage of knowing the history of the previously drawn cards and can use this to their benefit when placing a bet on a decision.
                </li>
                <li>
                    Watch out for the Purple Joker cards. These cards are rare but can appear randomly throughout the game.
                    These cards are over and above the standard deck of 52 cards and are neither Red nor Black.
                    Drawing this card will result in a loss of the current turn for the player.
                </li>
                <li>
                    Players can select their desired stake amount by scrolling  the roll picker.
                </li>
                <li>
                    Previously drawn cards in each round are shown to the right of the game board.
                </li>
                <li>
                    Each winning turn (hand) pays double (2X) the stake amount.
                    This payout will become the new minimum required bet for the next turn (hand), this rule is applicable for all consecutive turns (hands) in a single round.
                    However, starting a new round or losing the current hand resets the minimum value to its initial amount as in the first turn (hand).
                </li>
                <li>
                    Players can start a new round at any point in the game.
                </li>
                <li>
                    Stakes and payouts are debited/credited on a per turn (hand) basis.
                </li>
                <li>
                    Players can view their betting history for this game by clicking on the “Bet History” button while playing the game.
                </li>
            </ol>
        </div>;
    }
}
