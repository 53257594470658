import "./redblack-summary.style.scss";
import { AccountingHelper } from "@tgg/shared";
import { RedBlackContext, RedBlackContextValue } from "../../contexts/redblack.context";
import React, { ReactNode } from "react";

export interface RedBlackSummaryProperties {
    onEndMatch?: () => void;
}

export class RedBlackSummary extends React.PureComponent<RedBlackSummaryProperties> {
    public render(): ReactNode {
        return <RedBlackContext.Consumer>
            {
                (context) => <div className="redblack-summary">
                    <table>
                        <tbody>
                            <tr>
                                <th>Total Bets</th>
                                <td>
                                    {context.currencySign ?? "$"}
                                    {AccountingHelper.formatMoney(context.match?.hands.reduce((a, b) => (b.amount ?? 0) + a, 0), context.decimals)}
                                </td>
                            </tr>
                            <tr>
                                <th>Total Winnings</th>
                                <td>
                                    {context.currencySign ?? "$"}
                                    {AccountingHelper.formatMoney(context.match?.hands.reduce((a, b) => (b.won ? (b.amount ?? 0) * 2 : 0) + a, 0), context.decimals)}
                                </td>
                            </tr>
                            <tr>
                                <th>Highest Bet</th>
                                <td>
                                    {context.currencySign ?? "$"}
                                    {AccountingHelper.formatMoney(context.match?.hands.reduce((a, b) => Math.max(a, b.amount ?? 0), 0), context.decimals)}
                                </td>
                            </tr>
                            <tr>
                                <th>Highest Winning</th>
                                <td>
                                    {context.currencySign ?? "$"}
                                    {AccountingHelper.formatMoney(context.match?.hands.reduce((a, b) => Math.max(b.won ? (b.amount ?? 0) * 2 : 0, a), 0), context.decimals)}
                                </td>
                            </tr>
                            <tr>
                                <th>Longest Win Streak</th>
                                <td>{
                                    AccountingHelper.formatNumber(
                                        Math.max(
                                            ...(
                                                context.match?.hands.reduce((res, n) => (n.won ? res[res.length - 1]++ : res.push(0), res), [ 0 ]) ?? [ 0 ]
                                            )
                                        )
                                    )
                                }</td>
                            </tr>
                        </tbody>
                    </table>
                    <button
                        onClick={() => this.onEndMatch(context)}
                    >
                        {context.match?.ended == null ? "START NEW" : "PLAY AGAIN"}
                    </button>
                </div>
            }
        </RedBlackContext.Consumer>;
    }

    private onEndMatch(context: RedBlackContextValue) {
        if (context.endMatch) {
            context.endMatch();
        }

        if (this.props.onEndMatch) {
            this.props.onEndMatch();
        }
    }
}
