import "./redblack-page.style.scss";
import { AudioHelper, GameConfiguration, ServiceException } from "@tgg/shared";
import { RedBlackBetPlacement } from "../redblack-bet-placement/redblack-bet-placement.component";
import { RedBlackCard } from "../redblack-card/redblack-card.component";
import { RedBlackContext, RedBlackContextValue, RedBlackMaxBetAmount, RedBlackMinBetAmount } from "../../contexts/redblack.context";
import { RedBlackHandPrediction } from "../../services/redblack/enums/redblack-hand-prediction.enum";
import { RedBlackHelper } from "../../common/helpers/redblack.helper";
import { RedBlackMatchDto } from "../../services/redblack/dtos/redblack-match.dto";
import { RedBlackMenu } from "../redblack-menu/redblack-menu.component";
import { RedBlackService } from "../../services/redblack/redblack.service";
import { RedBlackSplash } from "../redblack-splash/redblack-splash.components";
import classNames from "classnames";
import React, { CSSProperties } from "react";

interface RedBlackPageProperties {
    redblackService: RedBlackService;
    userId?: number;
    balance?: number;
    currency?: string;
    currencySign?: string;
    style?: CSSProperties;
    config?: GameConfiguration;
}

export default class RedBlackPage extends React.PureComponent<RedBlackPageProperties, {
    theme: RedBlackContextValue["theme"];
    match?: RedBlackMatchDto;
    isBusy: boolean;
    isDisabled: boolean;
    visibleHand: number;
}> {
    private lastHand = 0;
    private flipAudio: HTMLAudioElement | undefined;
    private slideAudio: HTMLAudioElement | undefined;
    private isComponentMounted = false;

    public constructor(props: RedBlackPageProperties) {
        super(props);
        this.state = {
            theme: "light",
            isBusy: false,
            isDisabled: props.userId == null,
            visibleHand: 0,
        };
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;
        this.componentDidUpdate({ redblackService: this.props.redblackService }, this.state);

        if (!this.flipAudio) {
            void AudioHelper.loadAudio("/redblack-card-flip.mp3").then(
                (a) => this.flipAudio = a
            );
        }

        if (!this.slideAudio) {
            void AudioHelper.loadAudio("/redblack-card-slide.mp3").then(
                (a) => this.slideAudio = a
            );
        }
    }

    public componentDidUpdate(oldProps: RedBlackPageProperties, oldState: this["state"]): void {
        if (oldProps.userId !== this.props.userId) {
            if (this.props.userId) {
                this.setState(
                    {
                        isDisabled: false,
                    },
                    () => void this.getMatch()
                );
            } else {
                this.setState(
                    {
                        isDisabled: true,
                        match: undefined,
                    }
                );
            }
        }

        if (this.state.match?.hands.length !== oldState.match?.hands.length) {
            if ((this.state.match?.hands.length ?? 0) > this.lastHand) {
                AudioHelper.playAudio(this.flipAudio);
            }
            this.lastHand = this.state.match?.hands.length ?? 0;
        }
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public render(): JSX.Element {
        return <article className="redblack" style={this.props.style}>
            <RedBlackSplash />
            <RedBlackContext.Provider
                value={
                    {
                        theme: this.state.theme,
                        currentHand: this.state.match?.hands.length ?? 0,
                        lastHand: this.state.match?.hands.find((h) => this.state.match?.hands.every((h2) => h.id >= h2.id)),
                        visibleHand: this.state.visibleHand,
                        match: this.state.match,
                        balance: this.props.balance,
                        currency: this.props.currency,
                        currencySign: this.props.currencySign,
                        isBusy: this.state.isBusy,
                        predictHand: this.predictHand,
                        isDisabled: this.state.isDisabled,
                        endMatch: this.endMatch,
                        endHand: this.endHand,
                        getMatched: () => this.props.redblackService.getMyMatches({ direction: "Descending" }),
                        maxDeposit: this.props.config?.MaxDeposit ?? RedBlackMaxBetAmount,
                        minDeposit: this.props.config?.MinDeposit ?? RedBlackMinBetAmount,
                        decimals: Math.log10(this.props.config?.MinDeposit ?? 0) < 1 ? 2 : 0,
                    }
                }
            >
                <div>
                    <aside className="themes">
                        <RedBlackCard
                            theme="dark"
                            onClick={() => this.setState({ theme: "dark" })}
                        />
                        <RedBlackCard
                            theme="light"
                            onClick={() => this.setState({ theme: "light" })}
                        />
                        <h3>Choose your deck</h3>
                    </aside>
                    <div>
                        <RedBlackMenu />
                        <main>
                            <RedBlackCard />
                            {
                                new Array(5).fill(0).map(
                                    (_, i) => {
                                        const match = this.state.match;
                                        const hand = match?.hands && typeof match.hands[i] != "undefined" ? match.hands[i] : undefined;
                                        const slotClassName = (i < this.state.visibleHand && hand && !RedBlackHelper.isJoker(hand.result)) ? `redblack-result-${i + 1}` : undefined;
                                        return <RedBlackCard
                                            key={i}
                                            className={
                                                classNames(
                                                    slotClassName,
                                                    {
                                                        ["redblack-result"]: hand != null,
                                                    }
                                                )
                                            }
                                            result={hand?.result}
                                        />;
                                    }
                                )
                            }
                        </main>
                        <RedBlackBetPlacement />
                    </div>
                    <span className="watermark" />
                </div>
                <footer>© 2021-{new Date().getFullYear()} Nocturne Studios. All rights reserved.</footer>
            </RedBlackContext.Provider>
        </article>;
    }

    private getMatch = async () => {
        const service = this.props.redblackService;
        if (!service) {
            return;
        }

        this.setState(
            {
                isBusy: true,
            }
        );
        try {
            const match = await service.getMyMatch();

            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    match: match ?? undefined,
                    visibleHand: match?.hands.length ?? 0,
                    isBusy: false,
                }
            );
        } catch (error) {
            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    isBusy: false,
                }
            );
        }
    };

    private endMatch = async () => {
        const service = this.props.redblackService;
        const matchId = this.state.match?.id;
        if (!service || !matchId) {
            return;
        }

        if (this.state.match?.ended != null) {
            this.setState(
                {
                    match: undefined,
                    visibleHand: 0,
                },
                () => {
                    void this.getMatch();
                    AudioHelper.playAudio(this.slideAudio);
                    AudioHelper.playAudio(this.flipAudio, 500);
                }
            );
            return;
        }

        this.setState(
            {
                isBusy: true,
            }
        );

        try {
            await service.endMatch(matchId);

            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    match: undefined,
                    visibleHand: 0,
                    isBusy: false,
                },
                () => {
                    AudioHelper.playAudio(this.slideAudio);
                    AudioHelper.playAudio(this.flipAudio, 500);
                }
            );
        } catch (error) {
            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    isBusy: false,
                },
                () => {
                    if (error instanceof ServiceException && (error.status === 404 || error.status === 400)) {
                        void this.getMatch();
                    }
                }
            );
        }
    };

    private endHand = () => {
        this.setState(
            {
                visibleHand: this.state.match?.hands.length ?? 0,
            },
            () => AudioHelper.playAudio(this.slideAudio)
        );
    };

    private predictHand = async (prediction: RedBlackHandPrediction, amount: number) => {
        const service = this.props.redblackService;
        if (!service) {
            return;
        }

        this.setState(
            {
                isBusy: true,
            }
        );
        try {
            const match = await service.predictMatch(
                {
                    prediction,
                    amount,
                    matchId: this.state.match?.id ?? null,
                }
            );

            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    match,
                    isBusy: false,
                }
            );
        } catch (error) {
            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    isBusy: false,
                },
                () => {
                    if (error instanceof ServiceException && (error.status === 404 || error.status === 400)) {
                        void this.getMatch();
                    }
                }
            );
        }
    };
}
