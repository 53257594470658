import { RedBlackResultInfo } from "../interfaces/redblack-result-info.interface";

export abstract class RedBlackHelper {
    public static getResultInfo(result: number): RedBlackResultInfo {
        return {
            result,
            color: this.getCardColor(result),
            symbol: this.getCardSymbol(result),
            character: this.getCardCharacter(result),
            name: this.getCardName(result),
        };
    }

    public static getCardName(result: number): string {
        let cardName = "";
        let cardType = "";

        switch (this.getCardCharacter(result)) {
            case "A":
                cardName = "Ace";
                break;
            case "2":
                cardName = "Two";
                break;
            case "3":
                cardName = "Three";
                break;
            case "4":
                cardName = "Four";
                break;
            case "5":
                cardName = "Five";
                break;
            case "6":
                cardName = "Six";
                break;
            case "7":
                cardName = "Seven";
                break;
            case "8":
                cardName = "Eight";
                break;
            case "9":
                cardName = "Nine";
                break;
            case "10":
                cardName = "Ten";
                break;
            case "J":
                cardName = "Jack";
                break;
            case "Q":
                cardName = "Queen";
                break;
            case "K":
                cardName = "King";
                break;
        }

        switch (this.getCardSymbol(result)) {
            case "clubs":
                cardType = "Clubs";
                break;
            case "diamonds":
                cardType = "Diamonds";
                break;
            case "hearts":
                cardType = "Hearts";
                break;
            case "spades":
                cardType = "Spades";
                break;
            case "joker":
            default:
                return "Jocker";
        }

        return `${cardName} of ${cardType}`;
    }

    public static getCardCharacter(result: number): RedBlackResultInfo["character"] {
        if (this.isJoker(result)) {
            return undefined;
        }

        switch (result % 13) {
            default:
            case 0:
                return "A";
            case 1:
                return "2";
            case 2:
                return "3";
            case 3:
                return "4";
            case 4:
                return "5";
            case 5:
                return "6";
            case 6:
                return "7";
            case 7:
                return "8";
            case 8:
                return "9";
            case 9:
                return "10";
            case 10:
                return "J";
            case 11:
                return "Q";
            case 12:
                return "K";
        }
    }

    public static getCardSymbol(result: number): RedBlackResultInfo["symbol"] {
        // result is zero based, so middle of the stack would be 25.5
        const threshold = (52 - 1) / 2;

        switch (RedBlackHelper.getCardColor(result)) {
            case "red":
                return result > threshold ? "hearts" : "diamonds";
            case "black":
                return result > threshold ? "spades" : "clubs";
            case "joker":
            default:
                return "joker";
        }
    }

    public static getCardColor(result: number): RedBlackResultInfo["color"] {
        if (RedBlackHelper.isJoker(result)) {
            return "joker";
        }

        return (result % 2) === 0 ? "black" : "red";
    }

    public static isJoker(result: number): boolean {
        // result is zero based
        return result >= 52;
    }
}
