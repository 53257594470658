import "./redblack-splash.style.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { NocturneLoader } from "../nocturne-loader/nocturne-loader.component";
import React, { ReactNode } from "react";

export class RedBlackSplash extends React.PureComponent<unknown, {
    view?: "loading" | "home" | "game";
    isMobile?: boolean;
}> {
    public constructor(props: unknown) {
        super(props);
        this.state = {
            isMobile: undefined,
        };
    }

    public componentDidMount(): void {
        window.addEventListener("mousedown", this.onKeyDown);
        window.addEventListener("keydown", this.onKeyDown);

        this.onPageLoaded();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("mousedown", this.onKeyDown);
        window.removeEventListener("keydown", this.onKeyDown);
    }

    public render(): ReactNode {
        if (this.state.isMobile === undefined) {
            return null;
        }

        return <SwitchTransition mode="in-out">
            <CSSTransition
                classNames="fade"
                timeout={300}
                key={this.state.view}
                unmountOnExit={true}
            >
                <>
                    {
                        this.state.view === "loading" && <NocturneLoader
                            isLoading={this.state.view === "loading"}
                            isLoaded={true}
                            isMobile={this.state.isMobile}
                            percentage={100}
                            onExit={this.onLoaderExit}
                        />
                    }
                    {
                        this.state.view === "home" && <div className="redblack-splash">
                            <i />
                            <span>PRESS TO PLAY</span>
                            <footer>© 2021-{new Date().getFullYear()} Nocturne Studios. All rights reserved.</footer>
                        </div>
                    }
                </>
            </CSSTransition>
        </SwitchTransition>;
    }

    private onLoaderExit = () => {
        // this.enforceFullscreen();
        this.setState(
            {
                view: "home",
            }
        );
    };

    private onKeyDown = () => {
        if (this.state.view !== "home") {
            return;
        }

        this.setState(
            {
                view: "game",
            }
        );
    };

    private onPageLoaded = () => {
        this.setState(
            {
                view: "loading",
                isMobile: (typeof window !== "undefined" && window.innerWidth <= 900 && window.innerHeight <= 900) ||
                    (typeof localStorage !== "undefined" && !!(localStorage as Record<string, unknown>).mobile) ||
                    (typeof window !== "undefined" && window.navigator.maxTouchPoints > 1),
            }
        );
    };

    // private enforceFullscreen() {
    //     if (typeof window === "undefined" || !this.state.isMobile || !screenfull.isEnabled) {
    //         return;
    //     }

    //     void screenfull.request(window.document.body);
    // }
}
